.Home {
    background-color: #f2cc8f;
    height: 100vh;
}

.Menu {
    display: flex;
    justify-content: space-around;
}

.Menu > a {
    font-family: Ubuntu;
    font-weight: 500;
    font-size: 24px;
    text-decoration: none;
    color: #3d405b;
}

.About {
    display: flex;
    justify-content: center;
}

.About > p {
    color: #81b29a;
    font-family: Ubuntu;
    font-weight: 500;
    font-size: 20px;
}

.Contact {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: calc(50vh - 100px);
}

.Logo {
    width: 100px;
    padding-left: 35px;
    padding-right: 35px;
}
